
















import Vue from 'vue'
import { CMSComponentProperty } from '@simpl/cms-components'

export default Vue.extend({
  name: 'PropertyDrawerBoolean',

  props: {
    component: Object as () => Vue,
    prop: Object as () => CMSComponentProperty
  }
})
